@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    font-family: 'Poppins', sans-serif;
}

main {
    padding: 10px;
    height: 80vh;
    margin: 10vh 0 10vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

main::-webkit-scrollbar {
    width: 1rem;
}

main::-webkit-scrollbar-track {
    background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
    background: rgb(89, 211, 89);
}

form {
    height: 10vh;
    position: fixed;
    bottom: 0;
    background-color: rgb(24, 23, 23);
    width: 100%;
    max-width: 728px;
    display: flex;
    font-size: 1.5rem;
}

form button {
    width: 20%;
    background-color: rgb(89, 211, 89);
}


input {
    line-height: 1.5;
    width: 100%;
    font-size: 1.5rem;
    background: rgb(53, 53, 53);
    color: white;
    outline: none;
    border: none;
    padding: 0 10px;
}

button {
    background-color: #282c34;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2px 5px;
}

ul,
li {
    text-align: left;
    list-style: none;
}

p {
    max-width: 500px;
    margin-bottom: 12px;
    line-height: 24px;
    padding: 10px 20px;
    border-radius: 8px;
    position: relative;
    color: white;
    text-align: center;
}



.message {
    display: flex;
    align-items: center;
}


.sent {
    flex-direction: row-reverse;
    animation: newMessageRight 0.2s ease 0s 1 normal forwards;
}

.sent p {
    color: white;
    background: rgb(89, 211, 89);
    align-self: flex-end;
}

.received p {
    background: #e5e5ea;
    color: black;
    animation: newMessageLeft 0.2s ease 0s 1 normal forwards;

}




.sign-in-google {
    color: #ffffff;
    background: rgb(49, 112, 247);
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
}

.sign-in-github {
    color: #ffffff;
    background: rgb(0, 0, 0);
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
    margin-top: 8px;
}





@layer components {

    * {
        @apply bg-gray-900;
    }



}




@keyframes newMessageRight {
    0% {
        transform: scale(0);
        transform-origin: 100% 50%;
    }

    100% {
        transform: scale(1);
        transform-origin: 100% 50%;
    }
}


@keyframes newMessageLeft {
    0% {
        transform: scale(0);
        transform-origin: 0% 50%;
    }

    100% {
        transform: scale(1);
        transform-origin: 0% 50%;
    }
}